import React from "react";

import AllRoutes from "./routes/Routes";

import { configureFakeBackend } from "./helpers";

// Themes
// For Icons
import './assets/scss/icons.scss';

// For Default import Default.scss
import './assets/scss/config/default/app.scss'
import './assets/scss/config/default/bootstrap.scss'

// For Default import Corporate.scss
// import './assets/scss/config/corporate/app.scss'
// import './assets/scss/config/corporate/bootstrap.scss'

// For Default import Creative.scss
// import './assets/scss/config/creative/app.scss'
// import './assets/scss/config/creative/bootstrap.scss'

// For Default import Material.scss
// import './assets/scss/config/material/app.scss'
// import './assets/scss/config/material/bootstrap.scss'

// For Default import Modern.scss
// import './assets/scss/config/modern/app.scss'
// import './assets/scss/config/modern/bootstrap.scss'

// For Default import Saas.scss
// import './assets/scss/config/saas/app.scss'
// import './assets/scss/config/saas/bootstrap.scss'


// configure fake backend
configureFakeBackend();

const App = () => {
  return (
    <>
      <React.Fragment>
        <AllRoutes />
      </React.Fragment>
    </>
  )
};

export default App;
