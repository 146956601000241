import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetBasic, PostBasic, PutBasic } from "../../api/actions";
import { Review } from "../../interface/review";
import ReviewInfo from "./info";
import { Answer } from "../../interface/answer";
import { Categories } from "../../interface/categorie";
import { SubCategories } from "../../interface/subCategorie";
import { Question } from "../../interface/question";
import { CommentR } from "../../interface/commentR";
import { CommentA } from "../../interface/commentA";
import swal from "sweetalert2";


const ReviewDetails = () => {
  const [data, setData] = useState<Review | null>();
  const [answer, setAnswer] = useState<Answer[] | null>();
  const [key, setKey] = useState<string | null>("account");
  const [categories, setCategories] = useState<Categories[] | null>();
  const [subCategories, setSubCategories] = useState<SubCategories[] | null>();
  const [questions, setQuestions] = useState<Question[] | null>();
  const [resp, setResp] = useState<any[] | null>();
  const [disponible, setDisponible] = useState(false);

  const [showff, setShowFF] = useState(false);
  const [resultado, setResultado] = useState(0);
  const [condicion, setCondicion] = useState("");

  const [showc, setShowC] = useState(false);
  const handleCloseC = () => setShowC(false);

  const [showd, setShowD] = useState(false);
  const handleCloseD = () => setShowD(false);
  const handleShowD = (e: any) => {
    setShowD(true);
  };

  const [showf, setShowF] = useState(false);
  const handleCloseF = () => setShowF(false);
  const handleShowF = (e: any) => {
    setShowF(true);
  };

  const [countAnswers, setCountAnswers] = useState(0);
  const [countQuestion, setCountQuestion] = useState(0);
  const [showbutton, setMuestra] = useState(false);

  const [comments, setComments] = useState<CommentR[] | null>([]);
  const [comment, setComment] = useState<CommentR>({
    question_id: "",
    question: "",
    comment: "",
  });

  const [admincomments, setAdminComments] = useState<CommentA[] | null>([]);
  const [commentAdmin, setCommentAdmin] = useState<CommentA>({ comment: "" });
  const handleShowC = (e: any) => {
    setShowC(true);
  };

  const [show, setShow] = useState(false);
  const firstUpdate = useRef(true);
  const firstUpdate2 = useRef(true);
  const firstUpdate3 = useRef(true);
  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    GetReview();
    GetCategories();
    GetSubCategories();
    GetQuestions();
  }, []);

  const GetReview = () => {
    const url = "api/reviews/";
    GetBasic(url, id).then((data) => {
      setData(data);
      setAnswer(data.answers);
      setResp(data.questionResult);
      setComments(data.comments);
      setAdminComments(data.admincomments);
      data.status === 2 ? setDisponible(true) : setDisponible(false);

      try {
        setCountAnswers(data.answers.length);
      } catch {
        setCountAnswers(0);
      }
    });
  };

  const GetCategories = async () => {
    const url = "api/questions/categories";
    PostBasic(url, []).then((data) => {
      setCategories(data);
    });
  };

  const GetSubCategories = async () => {
    const url = "api/questions/subcategories";
    PostBasic(url, []).then((data) => {
      setSubCategories(data);
    });
  };

  const GetQuestions = async () => {
    const url = "api/questions/";
    PostBasic(url, []).then((data) => {
      setQuestions(data);
      setCountQuestion(data.length);
    });
  };

  //guardo los comentarios
  const updateReviewCommentsAdmin = async (i: any) => {
    i.preventDefault();
    const test = { comment: commentAdmin.comment };
    await setAdminComments((admincomments) => [
      ...(Array.isArray(admincomments) ? admincomments : []),
      test,
    ]);
    return false;
  };

  const handleShow = (e: any) => {
    const element = document.getElementById(e + "1");
    const title = element?.getAttribute("data-pregunta");

    setComment({
      ...comment,
      ["question_id"]: e,
      ["question"]: title && title !== null ? title : "",
    });
    setShow(true);
  };

  const handleInputChangeComment = (event: any) => {
    setComment({
      ...comment,
      [event.target.name]: event.target.value,
    });
  };

  const removeAnswer = async (e: any) => {
    if (answer && e) {
      const filter = answer.filter((answer) => answer.question_id !== e);
      setAnswer(filter);
    }
  };

  const handleComments = async (i: any) => {
    i.preventDefault();

    const test = {
      question_id: comment.question_id,
      question: comment.question,
      comment: comment.comment,
    };

    await setComments((comments) => [
      ...(Array.isArray(comments) ? comments : []),
      test,
    ]);
    return false;
  };

  const handleInputChangeCommentAdmin = (event: any) => {
    setCommentAdmin({
      ...commentAdmin,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (firstUpdate3.current) {
      // Si es la primera renderización, actualiza la referencia y no hagas nada más
      firstUpdate3.current = false;
      return;
    }
    PutBasic(
      `api/reviews/updatecommentsadmin/`,
      { admincomments: admincomments },
      id
    ).then((data) => {
      setShowD(false);
      setCommentAdmin({ comment: "" });
    });
  }, [admincomments]);

  useEffect(() => {
    if (firstUpdate2.current) {
      // Si es la primera renderización, actualiza la referencia y no hagas nada más
      firstUpdate2.current = false;
      return;
    }
    PutBasic(`api/reviews/updatecomments/`, { comments: comments }, id).then(
      (data) => {
        setShow(false);
        setComment({ question_id: "", question: "", comment: "" });
      }
    );
  }, [comments]);

  const answerProcess = async (i: any) => {
    let div1 = document.getElementById(i.target.id);
    const title = div1?.getAttribute("data-pregunta") || "";
    const valor = div1?.getAttribute("data-preguntavalor") || "";
    const _redflag = div1?.getAttribute("data-preguntaredflag") || "";
    const _id = div1?.getAttribute("data-idans") || "";

    if (answer) {
      const item = answer.filter((item) => item.question_id === _id)[0];
      if (item) {
        await removeAnswer(_id);
      }
    }

    if (answer) {
      const test: Answer = {
        question_id: _id,
        question: title,
        answer: i.target.value,
        value: parseFloat(valor),
        redflag: _redflag === "true" ? true : false,
      };
      await setAnswer((answer) => [...(answer as Answer[]), test]);
    } else {
      const test: Answer = {
        question_id: _id,
        question: title,
        answer: i.target.value,
        value: parseFloat(valor),
        redflag: _redflag === "true" ? true : false,
      };
      await setAnswer([test]);
    }
  };

  const handleChange = async (i: any) => {
    let div1 = document.getElementById(i.target.id);
    const title = div1?.getAttribute("data-pregunta") || "";
    const _redflag = div1?.getAttribute("data-preguntaredflag") || "";

    if (_redflag === "true" && i.target.value === "Falla") {
      swal
        .fire({
          title: "Estas seguro de la respuesta?",
          text: `El punto de revisión ${title} es un punto de falla no aceptada! Deseas finalizar y descartar el vehiculo?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1abc9c",
          cancelButtonColor: "#f1556c",
          confirmButtonText: "Si, Descartar Vehiculo!",
          cancelButtonText: "No, cancelar!",
        })
        .then(async function (result) {
          if (result.value) {
            await answerProcess(i);

            PutBasic(`api/reviews/finish/`, {}, id).then((data) => {
              setCondicion(data.condicion);
              setResultado(data.puntuacion);
              navigate(0);
            });
          }
        });
    } else {
      answerProcess(i);
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      // Si es la primera renderización, actualiza la referencia y no hagas nada más
      firstUpdate.current = false;
      return;
    }
    PutBasic(`api/reviews/`, { answers: answer }, id).then((data) => {
      setResp(data.questionResult);
      setCountAnswers(data.answers.length);
    });
  }, [answer]);

  //obtengo resultado
  const handleResultado = async (i: any) => {
    i.preventDefault();
    //setShowF(true);
    PostBasic(`api/reviews/finish/${id}`, { answers: answer }).then((data) => {
      setCondicion(data.condicion);
      setResultado(data.puntuacion);
    });
  };

  const showFinalizar = () => {
    if (countAnswers > 0 && countAnswers === countQuestion) {
      setMuestra(true);
    } else {
      setMuestra(false);
    }
  };

  useEffect(() => {
    showFinalizar();
  }, [countAnswers, countQuestion]);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Proceso de Revisión", path: "/review/details" },
          {
            label: "Detalle",
            path: "/review/details",
            active: true,
          },
        ]}
        title={"Proceso de Revisión"}
      />
      <Card>
        <Card.Body>
          <Row>
            <Col lg={12} className="mb-2">


              <Button type="button" className="col-lg-2 col-12 mb-1 mr-lg-2 mr-0" variant="light" onClick={handleShowC}>
                Ver Comentarios
              </Button>
              {' '}
              <Button
                type="button"
                variant="primary"
                className="col-lg-2 col-12 mb-1 mr-lg-2 mr-0"
                onClick={handleShowD}
              >
                + Comentarios
              </Button>
{' '}
              {data &&
                answer &&
                answer.filter((item) => item.answer === "Falla").length > 0 ? (
                <Button
                  type="button"
                  className="btn bg-light-warning text-dark col-lg-2 col-12 mb-1 mr-lg-2 mr-0"
                  variant="warning"
                  onClick={handleShowF}

                >
                  {"Fallas Reportadas: " +
                    (answer && answer.filter((item) => item.answer === "Falla"))
                      .length}
                </Button>
              ) : null}
              {data &&
                showbutton &&
                showbutton === true &&
                data.status === 1 ? (
                <Button
                  type="button"
                  variant="danger"
                  style={{ marginLeft: "10px" }}
                  onClick={async () => {
                    //await handleResultado;
                    PostBasic(`api/reviews/finish/${id}`, {
                      answers: answer,
                    }).then((data) => {
                      setCondicion(data.condicion);
                      setResultado(data.puntuacion);
                      swal
                        .fire({
                          title: "El resultado de la revisión es el siguiente:",
                          text: ` ${data.condicion} : ${data.puntuacion}`,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#1abc9c",
                          cancelButtonColor: "#f1556c",
                          confirmButtonText: "Finalizar!",
                          cancelButtonText: "No, cancelar!",
                        })
                        .then(async function (result) {
                          if (result.value) {
                            PutBasic(`api/reviews/finish/`, {}, id).then(
                              (data) => {
                                navigate(0);
                              }
                            );
                          }
                        });
                    });
                  }}
                >
                  Finalizar
                </Button>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mb-2">
              {data && answer && (
                <ReviewInfo
                  data={data}
                  answers={answer}
                  countAnswers={countAnswers ? countAnswers : 0}
                  countQuestion={countQuestion ? countQuestion : 0}
                />
              )}
            </Col>
            <Col lg={12}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="Red Flag">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      {answer &&
                        categories &&
                        categories.map((categorie) => (
                          <Nav.Item>
                            <Nav.Link eventKey={categorie.name}>
                              {categorie.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content className="pt-0">
                      {answer &&
                        categories &&
                        subCategories &&
                        categories.map((categorie) => (
                          <Tab.Pane eventKey={categorie.name}>
                            <div>
                              {answer &&
                                subCategories &&
                                subCategories
                                  .filter(
                                    (item) => item.category_id === categorie._id
                                  )
                                  .map((subcategorie) => (
                                    <>
                                      <Accordion
                                        eventKey={subcategorie._id}
                                        as={Card.Text}
                                        variant="link"
                                      >
                                        <Accordion.Item
                                          eventKey={subcategorie._id.toString()}
                                        >
                                          <Accordion.Header>
                                            <i className="mdi mdi-help-circle me-1 text-primary"></i>
                                            {subcategorie.name} -
                                            <label>
                                              {questions &&
                                                questions.filter(
                                                  (item) =>
                                                    item.subCategory ===
                                                    subcategorie._id
                                                ).length}{" "}
                                              /
                                            </label>
                                            <span
                                              id={
                                                "subCategory" + subcategorie._id
                                              }
                                              className="ml-2"
                                            >
                                              {resp &&
                                                resp.filter(
                                                  (item) =>
                                                    item.subCategory ===
                                                    subcategorie._id
                                                ).length}
                                            </span>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <Col
                                              xs="12"
                                              className="margin-bottom"
                                            >
                                              <Row>
                                                {answer &&
                                                  answer.length > -1 &&
                                                  questions &&
                                                  questions
                                                    .filter(
                                                      (dato) =>
                                                        dato.category ===
                                                        categorie._id &&
                                                        dato.subCategory ===
                                                        subcategorie._id
                                                    )
                                                    .map((pregunta) => (
                                                      <>
                                                        <Col
                                                          xs="12"
                                                          lg="6"
                                                          style={{
                                                            marginBottom:
                                                              "10px",
                                                          }}
                                                        >
                                                          <Card
                                                            style={{
                                                              border:
                                                                answer &&
                                                                  answer.filter(
                                                                    (answer) =>
                                                                      answer.question_id ===
                                                                      pregunta._id
                                                                  )[0]
                                                                  ? ""
                                                                  : "2px solid red",
                                                            }}
                                                          >
                                                            <Card.Body>
                                                              <label className="titulo mb-2">
                                                                {pregunta.title}
                                                              </label>
                                                              <Form>
                                                                {["radio"].map(
                                                                  (type) => (
                                                                    <div
                                                                      key={`default-${type}`}
                                                                    >
                                                                      <Form.Check
                                                                        disabled={
                                                                          disponible
                                                                        }
                                                                        inline
                                                                        label="Falla"
                                                                        name="group1"
                                                                        value="Falla"
                                                                        type="radio"
                                                                        id={
                                                                          pregunta._id +
                                                                          "1"
                                                                        }
                                                                        data-idsub={
                                                                          subcategorie._id
                                                                        }
                                                                        data-pregunta={
                                                                          pregunta.title
                                                                        }
                                                                        data-preguntavalor={
                                                                          pregunta.value
                                                                        }
                                                                        data-preguntaredflag={pregunta.redflag.toString()}
                                                                        defaultChecked={
                                                                          answer &&
                                                                            answer.filter(
                                                                              (
                                                                                answer
                                                                              ) =>
                                                                                answer.question_id ===
                                                                                pregunta._id &&
                                                                                answer.answer ===
                                                                                "Falla"
                                                                            )[0]
                                                                            ? true
                                                                            : false
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChange(
                                                                            e
                                                                          )
                                                                        }
                                                                        data-idans={
                                                                          pregunta._id
                                                                        }
                                                                      />

                                                                      <Form.Check
                                                                        disabled={
                                                                          disponible
                                                                        }
                                                                        inline
                                                                        label="No Falla"
                                                                        name="group1"
                                                                        value="No Falla"
                                                                        type="radio"
                                                                        id={
                                                                          pregunta._id +
                                                                          "2"
                                                                        }
                                                                        data-idsub={
                                                                          subcategorie._id
                                                                        }
                                                                        data-pregunta={
                                                                          pregunta.title
                                                                        }
                                                                        data-preguntavalor={
                                                                          pregunta.value
                                                                        }
                                                                        data-preguntaredflag={pregunta.redflag.toString()}
                                                                        defaultChecked={
                                                                          answer &&
                                                                            answer.filter(
                                                                              (
                                                                                answer
                                                                              ) =>
                                                                                answer.question_id ===
                                                                                pregunta._id &&
                                                                                answer.answer ===
                                                                                "No Falla"
                                                                            )[0]
                                                                            ? true
                                                                            : false
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChange(
                                                                            e
                                                                          )
                                                                        }
                                                                        data-idans={
                                                                          pregunta._id
                                                                        }
                                                                      />

                                                                      {pregunta.apply && (
                                                                        <Form.Check
                                                                          disabled={
                                                                            disponible
                                                                          }
                                                                          inline
                                                                          label="N/A"
                                                                          name="group1"
                                                                          value="N/A"
                                                                          type="radio"
                                                                          data-idans={
                                                                            pregunta._id
                                                                          }
                                                                          id={
                                                                            pregunta._id +
                                                                            "3"
                                                                          }
                                                                          data-idsub={
                                                                            subcategorie._id
                                                                          }
                                                                          data-pregunta={
                                                                            pregunta.title
                                                                          }
                                                                          data-preguntavalor={
                                                                            pregunta.value
                                                                          }
                                                                          data-preguntaredflag={pregunta.redflag.toString()}
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleChange(
                                                                              e
                                                                            )
                                                                          }
                                                                          defaultChecked={
                                                                            answer &&
                                                                              answer.filter(
                                                                                (
                                                                                  answer
                                                                                ) =>
                                                                                  answer.question_id ===
                                                                                  pregunta._id &&
                                                                                  answer.answer ===
                                                                                  "N/A"
                                                                              )[0]
                                                                              ? true
                                                                              : false
                                                                          }
                                                                        // custom
                                                                        />
                                                                      )}

                                                                      <br></br>
                                                                      <br></br>
                                                                      <Button
                                                                        type="button"
                                                                        variant="light"
                                                                        onClick={(
                                                                          e
                                                                        ) =>
                                                                          handleShow(
                                                                            pregunta._id
                                                                          )
                                                                        }
                                                                      >
                                                                        agregar
                                                                        comentario
                                                                      </Button>
                                                                      <div></div>
                                                                    </div>
                                                                  )
                                                                )}
                                                              </Form>
                                                            </Card.Body>
                                                          </Card>
                                                        </Col>
                                                      </>
                                                    ))}
                                              </Row>
                                            </Col>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </>
                                  ))}
                            </div>
                          </Tab.Pane>
                        ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          {/* <Card>
            <Card.Body>
              <Row>
                <Col lg={6}>
                  {data && data._id && <FileUpload idfile={data._id} />}
                </Col>
                <Col lg={6}>
                  {data && data._id && <CommentComp _id={data._id} />}
                </Col>
              </Row>
            </Card.Body>
          </Card> */}
        </Card.Body>
      </Card>
      {comment && (
        <Modal show={show} onHide={handleClose}>
          <Form onSubmit={handleComments}>
            <Modal.Header closeButton>
              <Modal.Title>Agregar Comentario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                id="comment"
                name="comment"
                type="text"
                placeholder="Agregue Comentario"
                required
                value={comment && comment.comment}
                onChange={handleInputChangeComment}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button type="submit" variant="primary">
                Agregar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}

      {admincomments && (
        <Modal show={showd} onHide={handleCloseD}>
          <Form onSubmit={updateReviewCommentsAdmin}>
            <Modal.Header closeButton>
              <Modal.Title>Agregar Comentario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                id="comment"
                name="comment"
                type="text"
                placeholder="Agregue Comentario"
                required
                value={commentAdmin && commentAdmin.comment}
                onChange={handleInputChangeCommentAdmin}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseD}>
                Cerrar
              </Button>
              <Button type="submit" variant="primary">
                Agregar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}

      <Modal show={showc} onHide={handleCloseC} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Comentarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Comentarios Mecanico</h4>
          <hr></hr>
          {comments &&
            comments.map((item) => (
              <>
                <Card.Title>{item.question}</Card.Title>
                <p>{item.comment}</p>
              </>
            ))}

          <h4>Comentarios Admin</h4>
          <hr></hr>
          {admincomments &&
            admincomments.map((item) => (
              <>
                <p>{item.comment}</p>
              </>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseC}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showf} onHide={handleCloseF}>
        <Modal.Header closeButton>
          <Modal.Title>Fallas Reportadas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col sm={12}>
            {answer &&
              answer
                .filter((item) => item.answer === "Falla")
                .map((elemento) => (
                  <Col sm={12}>
                    <span>{elemento.question} </span>
                  </Col>
                ))}
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseF}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReviewDetails;
