import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Variable from "../screens/variable";
import CarvalueDashboard from "../screens/dashboard";
import Acquires from "../screens/acquire";
import ReviewDetails from "../screens/review";
// import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// auth2
const Login2 = React.lazy(() => import("../pages/auth2/Login2"));
const Logout2 = React.lazy(() => import("../pages/auth2/Logout2"));
const Register2 = React.lazy(() => import("../pages/auth2/Register2"));
const Confirm2 = React.lazy(() => import("../pages/auth2/Confirm2"));
const ForgetPassword2 = React.lazy(
  () => import("../pages/auth2/ForgetPassword2")
);
const LockScreen2 = React.lazy(() => import("../pages/auth2/LockScreen2"));
const SignInSignUp2 = React.lazy(() => import("../pages/auth2/SignInSignUp2"));

// dashboards
const SalesDashboard = React.lazy(() => import("../pages/dashboard/Sales/"));
const CRMDashboard = React.lazy(() => import("../pages/dashboard/CRM/"));
const AnalyticsDashboard = React.lazy(
  () => import("../pages/dashboard/Analytics/")
);

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
// - chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));
// - ecommece pages
const EcommerceProducts = React.lazy(
  () => import("../pages/apps/Ecommerce/Products")
);
const EcommerceProductsGrid = React.lazy(
  () => import("../pages/apps/Ecommerce/ProductsGrid")
);
const ProductDetail = React.lazy(
  () => import("../pages/apps/Ecommerce/ProductDetail")
);
const CreateProduct = React.lazy(
  () => import("../pages/apps/Ecommerce/CreateProduct")
);
const Customers = React.lazy(() => import("../pages/apps/Ecommerce/Customers"));
const Orders = React.lazy(() => import("../pages/apps/Ecommerce/Orders"));
const OrderDetail = React.lazy(
  () => import("../pages/apps/Ecommerce/OrderDetail")
);
const Sellers = React.lazy(() => import("../pages/apps/Ecommerce/Sellers"));
const Cart = React.lazy(() => import("../pages/apps/Ecommerce/Cart"));
const Checkout = React.lazy(() => import("../pages/apps/Ecommerce/Checkout"));
// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));
// - companies
const Companies = React.lazy(() => import("../pages/apps/Companies/"));
// - tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const TaskDetails = React.lazy(() => import("../pages/apps/Tasks/Details"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
// - contacts
const ContactsList = React.lazy(() => import("../pages/apps/Contacts/List/"));
const ContactsProfile = React.lazy(
  () => import("../pages/apps/Contacts/Profile/")
);
// - ticktes
const Tickets = React.lazy(() => import("../pages/apps/Tickets/"));
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Starter = React.lazy(() => import("../pages/other/Starter"));
const Timeline = React.lazy(() => import("../pages/other/Timeline"));
const Sitemap = React.lazy(() => import("../pages/other/Sitemap/"));
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
// - other
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const FAQ = React.lazy(() => import("../pages/other/FAQ"));
const SearchResults = React.lazy(() => import("../pages/other/SearchResults/"));
const CommingSoon = React.lazy(() => import("../pages/other/CommingSoon"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));
const Gallery = React.lazy(() => import("../pages/other/Gallery/"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));

// uikit
// -base ui
const Avatars = React.lazy(() => import("../pages/uikit/Avatars"));
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Carousels = React.lazy(() => import("../pages/uikit/Carousel"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const EmbedVideo = React.lazy(() => import("../pages/uikit/EmbedVideo"));
const GeneralUI = React.lazy(() => import("../pages/uikit/GeneralUI"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));
const Images = React.lazy(() => import("../pages/uikit/Images"));
const ListGroup = React.lazy(() => import("../pages/uikit/ListGroup"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Notifications = React.lazy(() => import("../pages/uikit/Notifications"));
const Offcanvases = React.lazy(() => import("../pages/uikit/Offcanvas"));
const Placeholders = React.lazy(() => import("../pages/uikit/Placeholders"));
const Portlets = React.lazy(() => import("../pages/uikit/Portlets"));
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Ribbons = React.lazy(() => import("../pages/uikit/Ribbons"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const TabsAccordions = React.lazy(
  () => import("../pages/uikit/TabsAccordions")
);
const TooltipsPopovers = React.lazy(
  () => import("../pages/uikit/TooltipsPopovers")
);
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
// - extended ui
const NestableList = React.lazy(() => import("../pages/uikit/NestableList"));
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSliders"));
const TourPage = React.lazy(() => import("../pages/uikit/TourPage"));
const SweetAlerts = React.lazy(() => import("../pages/uikit/SweetAlerts"));

// widgets
const Widgets = React.lazy(() => import("../pages/widgets/"));

// icons
const FeatherIcons = React.lazy(() => import("../pages/icons/FeatherIcons/"));
const RemixIcons = React.lazy(() => import("../pages/icons/RemixIcons/"));
const BoxIcons = React.lazy(() => import("../pages/icons/BoxIcons/"));
const WeatherIcons = React.lazy(() => import("../pages/icons/WeatherIcons/"));
const MDIIcons = React.lazy(() => import("../pages/icons/MDIIcons/"));
const FontAwesomeIcons = React.lazy(
  () => import("../pages/icons/FontAwesomeIcons/")
);

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
// const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// charts
const ApexChart = React.lazy(() => import("../pages/charts/Apex"));
const ChartJs = React.lazy(() => import("../pages/charts/ChartJs"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"));

//revision
const Inspection = React.lazy(() => import("../screens/inspection"));
const Rules = React.lazy(() => import("../screens/rule"));
const RulesDetails = React.lazy(() => import("../screens/rule/detail"));
const CreateClient = React.lazy(() => import("../screens/client/create"));
const Client = React.lazy(() => import("../screens/client/"));
const DetailClient = React.lazy(() => import("../screens/client/detail"));

const OrderDetails = React.lazy(() => import("../screens/order/detail"));
const ValidationDetails = React.lazy(()=> import("../screens/inspection/validationDetails"));
const BudgetDetails = React.lazy(()=> import("../screens/inspection/budgetDetails"));
const Brands = React.lazy(() => import("../screens/brand/index"));
const Models = React.lazy(() => import("../screens/model/index"));
const Quotes = React.lazy(() => import("../screens/quote/index"));
const FileUpload = React.lazy(() => import("../screens/file/upload"));
const Cars = React.lazy(() => import("../screens/car/index"));
const DetailCar = React.lazy(() => import("../screens/car/details"));
const CarStock = React.lazy(() => import("../screens/car/stock"));
const SaleDetails = React.lazy(() => import("../screens/sale/details"));
const CalendarQuote = React.lazy(() => import("../screens/calendar/index"));
const Meets =  React.lazy(() => import("../screens/calendar/list"));
const Sales =   React.lazy(() => import("../screens/sale/index"));
const QuotesDetails = React.lazy(() => import("../screens/quote/detail"));
const QuotesPending = React.lazy(() => import("../screens/quote/quotePending"));
const QuotesAssigned = React.lazy(() => import("../screens/quote/quoteAssigned"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: any;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
// const rootRoute: RoutesProps = {
//   path: "/",
//   exact: true,
//   element: () => <Root />,
//   route: Route,
// };
// dashboards
const inspectionRoutes: RoutesProps = {
  path: "/inspection/",
  name: "inspection",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/inspection/list",
      name: "inspectionList",
      element: <Inspection />,
      route: PrivateRoute,
    },
  ],
};
const acquirenRoutes: RoutesProps = {
  path: "/acquire/",
  name: "inspection",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/acquire/list",
      name: "acquireList",
      element: <Acquires />,
      route: PrivateRoute,
    },
  ],
};

const rulesRoutes: RoutesProps = {
  path: "/rules/",
  name: "Rules",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/rules/list",
      name: "RulesList",
      element: <Rules />,
      route: PrivateRoute,
    },
    {
      path: "/rules/details/:id",
      name: "RulesDetails",
      element: <RulesDetails />,
      route: PrivateRoute,
    },
  ],
};

const carsRoutes: RoutesProps = {
  path: "/cars/",
  name: "Cars",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/cars/list",
      name: "CarsList",
      element: <Cars />,
      route: PrivateRoute,
    },
    {
      path: "/cars/details/:id",
      name: "CarsDetails",
      element: <DetailCar />,
      route: PrivateRoute,
    },
    {
      path: "/cars/stock/",
      name: "CarStock",
      element: <CarStock />,
      route: PrivateRoute,
    },
  ],
};

const clientRoutes: RoutesProps ={
  path: "/client/",
  name: "client",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/client/create",
      name: "clientCreate",
      element: <CreateClient />,
      route: PrivateRoute,
      exact:true,
    },
    {
      path: "/client/list",
      name: "clientList",
      element: <Client />,
      route: PrivateRoute,
      exact:true,
    },
    {
      path: "/client/details/:id",
      name: "clientDetails",
      element: <DetailClient />,
      route: PrivateRoute,
      exact:true,
    }
  ],

};


const orderRoutes: RoutesProps ={
  path: "/order/",
  name: "order",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/order/details",
      name: "orderDetails",
      element: <Orders/>,
      route: PrivateRoute,
    },
    {
      path: "/order/list",
      name: "orderList",
      element: <Orders />,
      route: PrivateRoute,
    },
  ],

};

const validationRoutes: RoutesProps ={
  path: "/validation/",
  name: "validation",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      exact:true,
      path: "/validation/details/:id",
      name: "validationDetails",
      element: <ValidationDetails />,
      route: PrivateRoute,
    },
    {
      exact:true,
      path: "/budget/details/:id",
      name: "validationComplete",
      element: <BudgetDetails />,
      route: PrivateRoute,
    },
  ],

};

const brandRoutes: RoutesProps ={
  path: "/brand/",
  name: "brand",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      exact:true,
      path: "/brand/list",
      name: "brandList",
      element: <Brands />,
      route: PrivateRoute,
    },
    {
      exact:true,
      path: "/brand/details/:id",
      name: "brandDetails",
      element: <BudgetDetails />,
      route: PrivateRoute,
    },
  ],

};

const modelRoutes: RoutesProps ={
  path: "/model/",
  name: "/model",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      exact:true,
      path: "/model/list",
      name: "/modelList",
      element: <Models />,
      route: PrivateRoute,
    },
    {
      exact:true,
      path: "/model/details/:id",
      name: "/modelDetails",
      element: <BudgetDetails />,
      route: PrivateRoute,
    },
  ],

};

const quoteRoutes: RoutesProps ={
  path: "/quote/",
  name: "/quote",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      exact:true,
      path: "/quote/list",
      name: "/quoteList",
      element: <Quotes />,
      route: PrivateRoute,
    },
    {
      exact:true,
      path: "/quote/pending",
      name: "/quotesPending",
      element: <QuotesPending />,
      route: PrivateRoute,
    },
    {
      exact:true,
      path: "/quote/assigned",
      name: "/quotesAssigned",
      element: <QuotesAssigned />,
      route: PrivateRoute,
    },
    {
      exact:true,
      path: "/quote/details/:id",
      name: "/quoteDetails",
      element: <QuotesDetails />,
      route: PrivateRoute,
    },
  ],

};

const saleRoutes: RoutesProps ={
  path: "/sales/details/:id",
  name: "sale",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      exact:true,
      path: "/sales",
      name: "sales",
      element: <Sales />,
      route: PrivateRoute,
    },
    {
      exact:true,
      path: "/sale/details/:id",
      name: "/saledetails",
      element: <SaleDetails />,
      route: PrivateRoute,
    }
  ],

};

const variableRoutes: RoutesProps ={
  path: "/settings/details/",
  name: "settings",
  icon: "airplay",
  header: "Navigation",
  element: <Variable />,

};


const reviewRoutes: RoutesProps ={
  path: "/review/details/:id",
  name: "settings",
  icon: "airplay",
  header: "Navigation",
  element: <ReviewDetails />,

};



// const fileRoutes: RoutesProps ={
//   path: "/file/",
//   name: "/file",
//   icon: "airplay",
//   header: "Navigation",
//   children: [
//     {
//       exact:true,
//       path: "/file/upload",
//       name: "/fileList",
//       element: <FileUpload />,
//       route: PrivateRoute,
//     },
//   ],

// };



// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/dashboard/carvalue" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/sales",
      name: "Sales",
      element: <SalesDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/crm",
      name: "CRM",
      element: <CRMDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      element: <AnalyticsDashboard />,
      route: PrivateRoute,
    },
  ],
};

// apps
const chatAppRoutes: RoutesProps = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "ri-message-2-line",
  element: <ChatApp />,
};

const ecommerceAppRoutes = {
  path: "/apps/ecommerce",
  name: "eCommerce",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "shopping-cart",
  children: [
    {
      path: "/apps/ecommerce/products",
      name: "Products",
      element: <EcommerceProducts />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/products-grid",
      name: "Products",
      element: <EcommerceProductsGrid />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/product-detail",
      name: "Product Detail",
      element: <ProductDetail />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/product-create",
      name: "Product Edit",
      element: <CreateProduct />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/customers",
      name: "Customers",
      element: <Customers />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/orders",
      name: "Orders",
      element: <Orders />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/order/detail",
      name: "Order Detail",
      element: <OrderDetail />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/sellers",
      name: "Sellers",
      element: <Sellers />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/shopping-cart",
      name: "Shopping Cart",
      element: <Cart />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/checkout",
      name: "Checkout",
      element: <Checkout />,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/meets/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "ri-calendar-2-line",
  element: <CalendarQuote />,
  children: [
    {
      path: "/meets/list",
      name: "Task List",
      element: <Meets />,
      route: PrivateRoute,
    },
  ],
};

const emailAppRoutes: RoutesProps = {
  path: "/apps/email",
  name: "Email",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "ri-mail-line",
  children: [
    {
      path: "/apps/email/inbox",
      name: "Inbox",
      element: <Inbox />,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/details",
      name: "Email Details",
      element: <EmailDetail />,
      route: PrivateRoute,
    },
  ],
};

const companiesAppRoutes = {
  path: "/apps/companies",
  name: "Companies",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "ri-building-4-line",
  element: <Companies />,
};

const taskAppRoutes = {
  path: "/apps/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "ri-task-line",
  children: [
    {
      path: "/apps/tasks/list",
      name: "Task List",
      element: <TaskList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/details",
      name: "Task List",
      element: <TaskDetails />,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/kanban",
      name: "Kanban",
      element: <Kanban />,
      route: PrivateRoute,
    },
  ],
};

const ticketsRoutes: RoutesProps = {
  path: "/apps/tickets",
  name: "Tickets",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "ri-customer-service-2-line",
  element: <Tickets />,
  header: "Apps",
};

const contactsRoutes = {
  path: "/apps/contacts",
  name: "Contacts",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "ri-profile-line",
  children: [
    {
      path: "/apps/contacts/list",
      name: "Task List",
      element: <ContactsList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/contacts/profile",
      name: "Profile",
      element: <ContactsProfile />,
      route: PrivateRoute,
    },
  ],
};

const fileAppRoutes = {
  path: "/apps/file-manager",
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  element: <FileManager />,
};

const dashboardCarvalueRoutes = {
  path: "/dashboard/carvalue",
  name: "Dashboards",
  route: PrivateRoute,
  icon: "folder-plus",
  element: <CarvalueDashboard />,
};

const appRoutes = [
  chatAppRoutes,
  ecommerceAppRoutes,
  calendarAppRoutes,
  emailAppRoutes,
  companiesAppRoutes,
  taskAppRoutes,
  ticketsRoutes,
  contactsRoutes,
  fileAppRoutes,
  inspectionRoutes,
  rulesRoutes,
  clientRoutes,
  orderRoutes,
  validationRoutes,
  brandRoutes,
  modelRoutes,
  quoteRoutes,
  carsRoutes,
  saleRoutes,
  variableRoutes,
  reviewRoutes,
  dashboardCarvalueRoutes,
  acquirenRoutes,

];

// pages
const extrapagesRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "ri-pages-line",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      element: <Starter />,
      route: PrivateRoute,
    },
    {
      path: "/pages/timeline",
      name: "Timeline",
      element: <Timeline />,
      route: PrivateRoute,
    },
    {
      path: "/pages/sitemap",
      name: "Sitemap",
      element: <Sitemap />,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      element: <Invoice />,
      route: PrivateRoute,
    },
    {
      path: "/pages/faq",
      name: "FAQ",
      element: <FAQ />,
      route: PrivateRoute,
    },
    {
      path: "/pages/serach-results",
      name: "Search Results",
      element: <SearchResults />,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      element: <Pricing />,
      route: PrivateRoute,
    },
    {
      path: "/pages/gallery",
      name: "Gallery",
      element: <Gallery />,
      route: PrivateRoute,
    },
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      element: <Error404Alt />,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: "/ui",
  name: "Components",
  icon: "ri-pencil-ruler-2-line",
  header: "UI Elements",
  children: [
    {
      path: "/ui/base",
      name: "Base UI",
      children: [
        {
          path: "/ui/avatars",
          name: "Avatars",
          element: <Avatars />,
          route: PrivateRoute,
        },
        {
          path: "/ui/buttons",
          name: "Buttons",
          element: <Buttons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/cards",
          name: "Cards",
          element: <Cards />,
          route: PrivateRoute,
        },
        {
          path: "/ui/carousel",
          name: "Carousel",
          element: <Carousels />,
          route: PrivateRoute,
        },
        {
          path: "/ui/dropdowns",
          name: "Dropdowns",
          element: <Dropdowns />,
          route: PrivateRoute,
        },
        {
          path: "/ui/embedvideo",
          name: "EmbedVideo",
          element: <EmbedVideo />,
          route: PrivateRoute,
        },
        {
          path: "/ui/general",
          name: "General UI",
          element: <GeneralUI />,
          route: PrivateRoute,
        },
        {
          path: "/ui/grid",
          name: "Grid",
          element: <Grid />,
          route: PrivateRoute,
        },
        {
          path: "/ui/images",
          name: "Images",
          element: <Images />,
          route: PrivateRoute,
        },
        {
          path: "/ui/listgroup",
          name: "List Group",
          element: <ListGroup />,
          route: PrivateRoute,
        },
        {
          path: "/ui/modals",
          name: "Modals",
          element: <Modals />,
          route: PrivateRoute,
        },
        {
          path: "/ui/notifications",
          name: "Notifications",
          element: <Notifications />,
          route: PrivateRoute,
        },
        {
          path: "/ui/offcanvas",
          name: "Offcanvas",
          element: <Offcanvases />,
          route: PrivateRoute,
        },
        {
          path: "/ui/placeholders",
          name: "Placeholders",
          element: <Placeholders />,
          route: PrivateRoute,
        },
        {
          path: "/ui/portlets",
          name: "Portlets",
          element: <Portlets />,
          route: PrivateRoute,
        },
        {
          path: "/ui/progress",
          name: "Progress",
          element: <Progress />,
          route: PrivateRoute,
        },
        {
          path: "/ui/ribbons",
          name: "Ribbons",
          element: <Ribbons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/spinners",
          name: "Spinners",
          element: <Spinners />,
          route: PrivateRoute,
        },
        {
          path: "/ui/tabs-accordions",
          name: "Tabs & Accordions",
          element: <TabsAccordions />,
          route: PrivateRoute,
        },
        {
          path: "/ui/tooltips-popovers",
          name: "Tooltips & Popovers",
          element: <TooltipsPopovers />,
          route: PrivateRoute,
        },
        {
          path: "/ui/typography",
          name: "Typography",
          element: <Typography />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/extended",
      name: "Extended UI",
      children: [
        {
          path: "/extended-ui/nestable",
          name: "Nestable List",
          element: <NestableList />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/rangesliders",
          name: "Range Sliders",
          element: <RangeSliders />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/sweet-alert",
          name: "Sweet Alert",
          element: <SweetAlerts />,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/tour",
          name: "Tour Page",
          element: <TourPage />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/widgets",
      name: "Widgets",
      element: <Widgets />,
      route: PrivateRoute,
    },
    {
      path: "/ui/icons",
      name: "Icons",
      children: [
        {
          path: "/ui/icons/feather",
          name: "Feather Icons",
          element: <FeatherIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/mdi",
          name: "Material Design",
          element: <MDIIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/font-awesome",
          name: "Font Awesome 5",
          element: <FontAwesomeIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/remix",
          name: "Remix",
          element: <RemixIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/boxicons",
          name: "Box Icons",
          element: <BoxIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/weather",
          name: "Weather Icons",
          element: <WeatherIcons />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/forms",
      name: "Forms",
      children: [
        {
          path: "/ui/forms/basic",
          name: "Basic Elements",
          element: <BasicForms />,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/advanced",
          name: "Form Advanced",
          element: <FormAdvanced />,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/validation",
          name: "Form Validation",
          element: <FormValidation />,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/wizard",
          name: "Form Wizard",
          element: <FormWizard />,
          route: PrivateRoute,
        },
        // {
        //   path: "/ui/forms/upload",
        //   name: "File Upload",
        //   element: <FileUpload />,
        //   route: PrivateRoute,
        // },
        {
          path: "/ui/forms/editors",
          name: "Editors",
          element: <Editors />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/tables",
      name: "Tables",
      children: [
        {
          path: "/ui/tables/basic",
          name: "Basic",
          element: <BasicTables />,
          route: PrivateRoute,
        },
        {
          path: "/ui/tables/advanced",
          name: "Advanced",
          element: <AdvancedTables />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/charts",
      name: "Charts",
      children: [
        {
          path: "/ui/charts/apex",
          name: "Apex",
          element: <ApexChart />,
          route: PrivateRoute,
        },
        {
          path: "/ui/charts/chartjs",
          name: "Chartjs",
          element: <ChartJs />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/maps",
      name: "Maps",
      children: [
        {
          path: "/ui/googlemaps",
          name: "Google Maps",
          element: <GoogleMaps />,
          route: PrivateRoute,
        },
        {
          path: "/ui/vectorMaps",
          name: "Google Maps",
          element: <VectorMaps />,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: "/auth/login2",
    name: "Login2",
    element: <Login2 />,
    route: Route,
  },
  {
    path: "/auth/logout2",
    name: "Logout2",
    element: <Logout2 />,
    route: Route,
  },
  {
    path: "/auth/register2",
    name: "Register2",
    element: <Register2 />,
    route: Route,
  },
  {
    path: "/auth/confirm2",
    name: "Confirm2",
    element: <Confirm2 />,
    route: Route,
  },
  {
    path: "/auth/forget-password2",
    name: "Forget Password2",
    element: <ForgetPassword2 />,
    route: Route,
  },
  {
    path: "/auth/signin-signup2",
    name: "SignIn-SignUp2",
    element: <SignInSignUp2 />,
    route: Route,
  },
  {
    path: "/auth/lock-screen2",
    name: "Lock Screen2",
    element: <LockScreen2 />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "*",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/comming-soon",
    name: "Coming Soon",
    element: <CommingSoon />,
    route: Route,
  },
];



// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// // All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
