import axios from "axios";
import { urlApi } from "../constants/app";

async function GetDataPaginator (endPoint:string, page:number=1, filter:string): Promise<any> {

    const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
    const datos = { pagina:(page+1), elementosPorPagina:10, filtro:filter }
    const URL = `${urlApi}${endPoint}`;
    const { data } = await axios.post(URL, datos, config);
    return data;
}


async function GetBasic (endPoint:string, param:any): Promise<any> {

  const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
  const URL = `${urlApi}${endPoint}${param}`;
  const { data } = await axios.get(URL, config);
  return data;
}

async function PostBasic (endPoint:string, values:any): Promise<any> {

  const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
  const URL = `${urlApi}${endPoint}`;
  const { data } = await axios.post(URL, values, config);
  return data;
}



async function PutBasic (endPoint:string, values:any, param:any): Promise<any> {

  const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
  const URL = `${urlApi}${endPoint}${param}`;
  const { data } = await axios.put(URL, values, config);
  return data;
}

async function PostUpload (endPoint:string, file: File, idFile:string): Promise<any> {

   const formData = new FormData();
   formData.append('file', file);
   formData.append('name', file.name);
   formData.append('idFile', idFile);
   const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  //  const response = await fetch('http://localhost:3050/api/client/upload/', {
  //   method: 'POST',
  //   body: file,
    
  // });
  // const datos = { file:file, name:file.name }

  
  const URL = `${urlApi}${endPoint}`;
  const { data } = await axios.post(URL, formData, config);
  return data;
}

export { GetDataPaginator , PostBasic , PutBasic , GetBasic, PostUpload}