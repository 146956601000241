import { useEffect, useState, useMemo, useCallback } from "react";
import { Row, Col, Card, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

// components
import PageTitle from "../components/PageTitle";
import Table from "../components/Table";
import Spinner from "../components/Spinner";

import { GetDataPaginator } from "../api/actions";
import React from "react";
import { useDebounce } from "../hooks";
interface TableProps {
  url: string;
  title:string;
  head:{  label: string;
    path: string;
    active?: boolean;
  }[];
  columns: {
    Header: string;
    accessor: string;
    sort?: boolean;
    Cell?: any;
    className?: string;
  }[];
}
const FullTable = (props: TableProps) => {
  const url = props["url"] || "";
  const _columns = props["columns"];
  const _head = props["head"];
  const _title = props["title"];
  const [_data, setData] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState(10);
  const [inputValue, setValue] = useState<any>("");
  const debouncedInputValue = useDebounce(inputValue, 500);

  useEffect(() => {
    setPageCount(pageCount);
    setPageIndex(pageIndex);
  }, [pageCount, pageIndex]);

  const filterPages = useCallback(
    (visiblePages: any, totalPages: number) => {
      return visiblePages.filter((page: any) => page <= pageCount);
    },
    [pageCount]
  );

  const getVisiblePages = useCallback(
    (page: number | null, total: number) => {
      if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6], total);
      } else {
        if (page! % 5 >= 0 && page! > 4 && page! + 2 < total) {
          return [1, page! - 1, page!, page! + 1, total];
        } else if (page! % 5 >= 0 && page! > 4 && page! + 2 >= total) {
          return [1, total - 3, total - 2, total - 1, total];
        } else {
          return [1, 2, 3, 4, 5, total];
        }
      }
    },
    [filterPages]
  );

  const changePage = (page: number) => {
    const activePage = pageIndex + 1;
    setPageIndex(page - 1);
    if (page === activePage) {
      return;
    }

    const visiblePages = getVisiblePages(page, pageCount);
    setVisiblePages(filterPages(visiblePages, pageCount));
  };

  useEffect(() => {
    const visiblePages = getVisiblePages(null, pageCount);
    setVisiblePages(visiblePages);
  }, [pageCount, getVisiblePages]);

  const [visiblePages, setVisiblePages] = useState<number[]>(
    getVisiblePages(null, pageCount)
  );

  const activePage: number = pageIndex + 1;

  useEffect(() => {
    
    GetDataPaginator(url, pageIndex, debouncedInputValue)
      .then((result) => {
        setData(result.data);
        setPageCount(Math.ceil(result.totalItems / pageSize));
      })
      .catch((error) => {});
  }, [pageIndex, pageSize, debouncedInputValue]);

  return (
    <>
      {/* <PageTitle
        breadCrumbItems={_head}
        title={_title}
      />
      <Row>
        <Col>
          <Card>
            <Card.Body> */}
              <div className="searchBoxClass">
                <span className="d-flex align-items-center">
                  Buesqueda:{" "}
                  <input
                    type="search"
                    value={inputValue || ""}
                    onChange={(e: any) => {
                      setValue(e.target.value);
                    }}
                    placeholder={`${0} records...`}
                    className="form-control react-table-search ms-3"
                  />
                </span>
              </div>
              {_data.length > 0 ? (
                <>
                  <Table
                    columns={_columns}
                    data={_data}
                    pageSize={10}
                    isSortable={true}
                    pagination={true}
                  />
                </>
              ) : (
                <Alert
                variant="success"
                className="bg-transparent text-secondary text-dark mt-3"
                
                >
                No encontramos resultados
              </Alert>
              )}
              <div
                className={classNames(
                  "d-lg-flex align-items-center text-center pb-1"
                )}
              >
                <ul className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
                  <li
                    key="prevpage"
                    className={classNames(
                      "page-item",
                      "paginate_button",
                      "previous",
                      {
                        disabled: activePage === 1,
                      }
                    )}
                    onClick={() => {
                      if (activePage === 1) return;
                      changePage(activePage - 1);
                    }}
                  >
                    <Link to="#" className="page-link">
                      <i className="mdi mdi-chevron-left"></i>
                    </Link>
                  </li>
                  {(visiblePages || []).map((page, index, array) => {
                    return array[index - 1] + 1 < page ? (
                      <React.Fragment key={page}>
                        <li className="page-item disabled d-none d-xl-inline-block">
                          <Link to="#" className="page-link">
                            ...
                          </Link>
                        </li>
                        <li
                          className={classNames(
                            "page-item",
                            "d-none",
                            "d-xl-inline-block",
                            {
                              active: activePage === page,
                            }
                          )}
                          onClick={(e: any) => changePage(page)}
                        >
                          <Link to="#" className="page-link">
                            {page}
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : (
                      <li
                        key={page}
                        className={classNames(
                          "page-item",
                          "d-none",
                          "d-xl-inline-block",
                          {
                            active: activePage === page,
                          }
                        )}
                        onClick={(e: any) => changePage(page)}
                      >
                        <Link to="#" className="page-link">
                          {page}
                        </Link>
                      </li>
                    );
                  })}
                  <li
                    key="nextpage"
                    className={classNames(
                      "page-item",
                      "paginate_button",
                      "next",
                      {
                        disabled: activePage === pageCount,
                      }
                    )}
                    onClick={() => {
                      if (activePage === pageCount) return;
                      changePage(activePage + 1);
                    }}
                  >
                    <Link to="#" className="page-link">
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            {/* </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </>
  );
};

export default FullTable;
