import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetBasic, PutBasic } from "../../api/actions";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";

const Varibale = () => {
  const { id } = useParams();

  const [value, setName] = useState<any>();
  const [valuepg, setNamePG] = useState<any>();
  const [rangoA, setRangoA] = useState<any>();
  const [rangoB, setRangoB] = useState<any>();
  const [rangoC, setRangoC] = useState<any>();
  const [valueM, setRangoM] = useState<any>();
  const [costoPoder, setCostoPoder] = useState<any>();
  const [costoCompraVenta, setCostoCompraVenta] = useState<any>();
  const [costoLI, setCostoLI] = useState<any>();
  const [costoCambioAceite, setCostoCambioAceite] = useState<any>();
  const [costoDetailing, setCostoDetailing] = useState<any>();
  const [costoFotos, setCostoFotos] = useState<any>();

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    const url = `api/constants/33`;

    GetBasic(url, id)
      .then((data) => {
        setName(data[0].value);
        setNamePG(data[1].value);
        setRangoA(data[2].value);
        setRangoB(data[3].value);
        setRangoC(data[4].value);
        setRangoM(data[5].value);
        setCostoPoder(data[6].value);
        setCostoCompraVenta(data[7].value);
        setCostoLI(data[8].value);
        setCostoCambioAceite(data[9].value);
        setCostoDetailing(data[10].value);
        setCostoFotos(data[11].value);
      })
      .catch((error) => {});
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      value: Number(value),
      valuepg:Number( valuepg),
      rangoA: Number(rangoA),
      rangoB: Number(rangoB),
      rangoC: Number(rangoC),
      mercado: Number(valueM),
      costoPoder: Number(costoPoder),
      costoCompraVenta: Number(costoCompraVenta),
      costoLI: Number(costoLI),
      costoCambioAceite: Number(costoCambioAceite),
      costoDetailing: Number(costoDetailing),
      costoFotos: Number(costoFotos),
    };

    const url = `api/constants/`;

    PutBasic(url, data, 33)
      .then((data) => {
      setMessage("Datos actualizados correctamente"); 
      })
      .catch((error) => {
        setError(true);
        setMessage("Error al actualizar los datos");
      });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={12}>
                      {" "}
                      <Card>
                        <Card.Header>Configuración Variables</Card.Header>
                        <Card.Body>
                          <Row>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Valor gasto revisión</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={value}
                                  onChange={(e) => setName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Limite: 0 - 10.000 </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={rangoA}
                                  onChange={(e) => setRangoA(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>
                                  Limite: 10.001 - 15.000{" "}
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={rangoB}
                                  onChange={(e) => setRangoB(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Limite: mayor a 15.000 </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={rangoC}
                                  onChange={(e) => setRangoC(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>
                                  Valor margen de ganancia
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={valuepg}
                                  onChange={(e) => setNamePG(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Factor Mercado</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={valueM}
                                  onChange={(e) => setRangoM(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Costo poder</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={costoPoder}
                                  onChange={(e) => setCostoPoder(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Costo compra venta</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={costoCompraVenta}
                                  onChange={(e) =>
                                    setCostoCompraVenta(e.target.value)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Costo Limpieza inyectores</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={costoLI}
                                  onChange={(e) => setCostoLI(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Costo cambio de aceite</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={costoCambioAceite}
                                  onChange={(e) =>
                                    setCostoCambioAceite(e.target.value)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Costo detailing</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={costoDetailing}
                                  onChange={(e) => setCostoDetailing(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Costo fotos</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={costoFotos}
                                  onChange={(e) => setCostoFotos(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  *
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>


                            <Col lg={12} className="text-center">
                              <Button type="submit">Actualizar</Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>

            <Col lg={12} className="mt-2">
              {message && (
                <Alert
                  key={0}
                  variant={error ? "danger" : "success"}
                  className={
                    error
                      ? "fade alert alert-danger show"
                      : "fade alert alert-success show"
                  }
                >
                  {message}
                </Alert>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Varibale;
