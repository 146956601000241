import FullTable from "../../components/FullTable";
import {   Card, Col,  Row, } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import { Link,  } from "react-router-dom";
import classNames from "classnames";


const head = [
  { label: "ventas", path: "/sales" },
  {
    label: "Listado de citas",
    path: "/sales",
    active: true,
  },
];

const title = "Ventas";

const Acquires = () => {
  const columns = [
    {
      Header: "Id",
      accessor: "_id",
      Cell: ({ row }: any) =>
        row.original._id.substring(18, row.original._id.lenght).toUpperCase(),

      className: "w-20",
    },
    {
      Header: "Vehiculo",
      accessor: "carModel",
      Cell: ({ row }: any) => {
        if (
          row.original.carModel === null ||
          row.original.carModel === undefined
        ) {
          return (
            <h5 className={classNames("badge", "bg-soft-danger text-danger")}>NO ASIGNADO</h5>
          );
        } else {
          return (
            row.original.carModel.brand +
            " " +
            row.original.carModel.model +
            " " +
            row.original.carModel.carId
          );
        }
      },
      className: "w-20",
    },
    {
      Header: "Client",
      accessor: "clientModel",
      Cell: ({ row }: any) => {
        if (
          row.original.clientModel === null ||
          row.original.clientModel === undefined
        ) {
          return (
            <h5 className={classNames("badge", "bg-soft-danger text-danger")}>NO ASIGANDO</h5>
          );
        } else {
          return (
            row.original.clientModel.name.toUpperCase() +
            " " +
            row.original.clientModel.lastName.toUpperCase()
          );
        }
      },
      className: "w-20",
    },
    {
      Header: "Estatus",
      accessor: "status",
      Cell: ({ row }: any) => {
        let statusClass = "";
        if (
          row.original.status === "EN PROGRESO" ||
          row.original.status === "INICIADO" ||
          row.original.status === "RESERVADO"
        ) {
          statusClass = "bg-info";
        } else if (
          row.original.status === "FINALIZADO" ||
          row.original.status === "PAGO REALIZADO"
        ) {
          statusClass = "bg-info";
        } else if (
          row.original.status === "RECHAZADO" ||
          row.original.status === "NO ASISTIO"
        ) {
          statusClass = "bg-danger";
        } else if (row.original.status === "EN ESPERA") {
          statusClass = "bg-warning";
        }else{
          statusClass = "bg-info";
        }
        return (
          <h5 className={classNames("badge", statusClass)}>
            {row.original.status}
          </h5>
        );
      },
      className: "w-20",
    },
    {
      Header: "Accón",
      accessor: "accion",
      button: true,
      Cell: ({ row }: any) => (
        <>
          <Link
            id={row.original._id}
            className="btn btn-secondary btn-xs"
            to={`/validation/details/${row.original.review_id}`}
          >
            Detalle
          </Link>
        </>
      ),
    },
  ];
  const url = "api/acquire/list";
  return (
    <>
      <PageTitle breadCrumbItems={head} title={title} />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <FullTable
                url={url}
                columns={columns}
                head={head}
                title={title}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Acquires;
