import { Col, ProgressBar, Row, Table } from "react-bootstrap";
import { Review } from "../../interface/review";
import { Answer } from "../../interface/answer";
import { useEffect, useState } from "react";

const ReviewInfo = (props: {
  data: Review;
  answers: Answer[];
  countAnswers: Number;
  countQuestion: Number;
}) => {
  const review = props.data || {};
  const answers = props.answers || [];
  const countAnswers = props.countAnswers || 0;
  const countQuestion = props.countQuestion || 0;
  const [avance, setAvance] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const _avance = (Number(countAnswers) / Number(countQuestion)) * 100;
    setAvance(_avance);
  }, [countAnswers, countQuestion]);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 20;
      if (show !== isScrolling) {
        setIsScrolling(show);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolling]);

  return (
    <>
      <Row>
        <Col lg={4}>
          <div className="border p-3 mt-4 mt-lg-0 rounded">
            <div className="form-check-inline font-16">
              <h4>Datos Vehiculo</h4>
            </div>
            <hr className="mt-0 mb-0" />
            <>
              <Table borderless size="sm" className="mb-0">
                <tbody>
                  <tr>
                    <th scope="row">Marca</th>
                    <td>
                      {review.reviewResult.brand} - {review.reviewResult.model}{" "}
                      - {review.reviewResult.year}{" "}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Modelo</th>
                    <td>
                      {review.cardId} - {review.color} -{" "}
                      {review.reviewResult.kmCar} KM
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Comentario</th>
                    <td>{review.reviewResult.comments}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          </div>
        </Col>
        <Col lg={4}>
          <div className="border p-3 mt-4 mt-lg-0 rounded">
            <div className="form-check-inline font-16">
              <h4>Datos Cliente</h4>
            </div>
            <hr className="mt-0 mb-0" />
            <>
              <Table borderless size="sm" className="mb-0">
                <tbody>
                  <tr>
                    <th scope="row">Nombre</th>
                    <td>
                      {review.reviewResult.name} -{" "}
                      {review.reviewResult.lastName}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Telefono</th>
                    <td>{review.reviewResult.phone}</td>
                  </tr>
                  <tr>
                    <th scope="row">Correo</th>
                    <td>{review.reviewResult.mail}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          </div>
        </Col>
        <Col lg={4}>
          <div
            className={`border p-3 mt-4 mt-lg-0 rounded ${
               review.conditionResult === "Descartado"
                ? "border-warning bg-warning bg-opacity-50"
                : review.conditionResult === "No set" ? "": "border-primary bg-primary bg-opacity-50"
            }`}
          >
            <div className="form-check-inline font-16">
              <h4>
                Estatus :{" "}
                {review.status === 2 ? (
                  <span>Finalizado</span>
                ) : (
                  <span>En Proceso</span>
                )}{" "}
              </h4>
            </div>
            <hr className="mt-0 mb-0" />
            <>
              <Table borderless size="sm" className="mb-0">
                <tbody>
                  <tr>
                    <th scope="row">Resultado</th>
                    <td>
                      {review.conditionResult} 
                    </td>
                    <th>Score:</th>
                    <td>{review.score.toFixed(0)} </td>
                  </tr>
                  <tr>
                    <th scope="row">Progreso</th>
                    <td>{parseFloat(avance.toString()).toFixed(0)} % </td>
                    <th> Res:</th>
                    <td>{`  ${countAnswers} de ${countQuestion}`}</td>
                  </tr>
                  <tr>
                    <th scope="row">Encargado</th>
                    <td>{review.userResult.name}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          </div>
        </Col>
        {/* <Col lg={isScrolling ? 4 : 12} lgOffset={2} className={`mt-1 text-center ${isScrolling ? 'position-fixed' : ''}`} style={isScrolling ? { bottom: 10, zIndex: 100 } : {}}> */}
        <Col lg={12}>
          <ProgressBar
            variant="defualt"
            now={avance}
            label={avance.toFixed(0) + "%"}
            className="mb-2 mt-2 progress-xl"
          />

        </Col>
      </Row>
    </>
  );
};

export default ReviewInfo;
