const axios = require('axios');

export async function llamadaAsincrona(email: string, password: string ) {
  try {
    const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      console.log({email , password})
      const {data} = await axios.post(
        "https://api.carvalue.market/api/users/login",
        { email , password },
        config
      );
    //const respuesta = await axios.post('https://api.carvalue.market/api/users/login');
    
    return data;
  } catch (error) {
    return { error : error  }
  }
}



export default llamadaAsincrona;